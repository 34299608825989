import { Component } from '@angular/core';
import { BaseAlertComponent } from '../base-alert.component';
import { map } from 'rxjs/operators';
import { AlertBackgroundColorEnum } from '../../../../../../models/alerts/enum/alert-background-color.enum';

@Component({
  selector: 'app-banner-alert',
  templateUrl: './banner-alert.component.html',
  styleUrls: ['./banner-alert.component.scss']
})
export class BannerAlertComponent extends BaseAlertComponent {
  setupBindings(): void {}

  setupViews(): void {}

  public isDismissable$ = this.alert$.pipe(map(a => a?.dismissable));

  public dismissButtonSrc$ = this.alert$.pipe(
    map(a => {
      switch (a?.colourId) {
        case AlertBackgroundColorEnum.BannerGreen:
          return 'assets/icons/alerts/GreenClose.svg';
        case AlertBackgroundColorEnum.BannerBlue:
          return 'assets/icons/alerts/BlueClose.svg';
        case AlertBackgroundColorEnum.BannerYellow:
          return 'assets/icons/alerts/YellowClose.svg';
        case AlertBackgroundColorEnum.BannerRed:
          return 'assets/icons/alerts/RedClose.svg';
        case AlertBackgroundColorEnum.BannerGrey:
          return 'assets/icons/alerts/GreyClose.svg';
      }
    })
  );
}
