<div
  class="alert-container"
  [style.background-color]="backgroundColour$ | async"
  [style.border-color]="borderColor$ | async">
  <div class="alert-body">
    <div>
      <div class="alert-title">{{ title$ | async }}</div>
      <div class="alert-description">
        {{ description$ | async }}
      </div>
    </div>
    <div>
      <img *ngIf="isDismissable$ | async" class="dismiss-button" [src]="dismissButtonSrc$ | async" alt="Dismiss" />
    </div>
  </div>
</div>
