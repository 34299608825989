import { Component, inject, Input } from '@angular/core';
import { MembersDomainModel } from '../../../../domainModels/members-domain-model';
import { filter, map } from 'rxjs/operators';
import { MemberUserSearchResult } from '../../../../models/account/dto/member-user-search-result';
import { PillItem } from '../../../../models/shared/pill-item';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent {
  @Input() dispersedKey = '';
  @Input() employerId!: number;

  private membersDomainModel = inject(MembersDomainModel);

  public memberSearchFunction = (searchString: string) => {
    return this.membersDomainModel.searchMembersByIdOrName(searchString, this.employerId);
  };

  private _selectedMember = new BehaviorSubject<MemberUserSearchResult | null>(null);
  public selectedMember$ = this._selectedMember as Observable<MemberUserSearchResult | null>;
  public setSelectedMember = (member: MemberUserSearchResult | null) => this._selectedMember.next(member);

  public selectedMemberPillItem$ = this.selectedMember$.pipe(
    filter((member): member is MemberUserSearchResult => member !== null),
    map(member => new PillItem(`${member.name} (${member.id})`, true))
  );
}
