import { BaseDomainModel } from '../models/base/base-domain-model';
import { TransactionsAPI } from '../api/transactions-api';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Transaction } from '../models/transactions/transaction';
import { TypeService } from '../services/type/type-service';
import { TransactionEntryCodeType } from '../models/transactions/transaction-entry-code-type';
import { TransactionStatus } from '../models/transactions/transaction-status';
import { RemittanceTransaction } from '../models/transactions/remittance-transaction';

@Injectable()
export class TransactionDomainModel extends BaseDomainModel {
  constructor() {
    super();
    this.init();
  }

  private transactionsAPI = inject(TransactionsAPI);
  private typeService = inject(TypeService);

  private _entryCodeTypes = new BehaviorSubject<TransactionEntryCodeType[]>([]);
  public entryCodeTypes$ = this._entryCodeTypes as Observable<TransactionEntryCodeType[]>;

  private _transactionStatusTypes = new BehaviorSubject<TransactionStatus[]>([]);
  public transactionStatusTypes$ = this._transactionStatusTypes as Observable<TransactionStatus[]>;

  public init(): void {
    this.fetchEntryTypes();
    this.fetchTransactionStatusTypes();
  }

  public fetchTransactionById(id: number): Observable<Transaction> {
    return this.transactionsAPI.fetchTransactionById(id);
  }

  private fetchEntryTypes(): void {
    this.typeService.transactionEntryCodeTypes$.once(entryCodeTypes => this._entryCodeTypes.next(entryCodeTypes));
  }

  private fetchTransactionStatusTypes(): void {
    this.typeService.transactionStatusTypes$.once(transactionStatusTypes =>
      this._transactionStatusTypes.next(transactionStatusTypes)
    );
  }

  public getTransactionsForRemittance(remittanceId: number): Observable<RemittanceTransaction[]> {
    return this.transactionsAPI.getTransactionsForRemittance(remittanceId);
  }
}
