import { RemittanceFileRecord } from './remittance-file-record';
import { DateUtils } from '../../utils/date-utils';
import { Deserializable } from '../protocols/deserializable';

export class ContributionEntry implements Deserializable {
  id!: string;
  contributionId!: string;
  codeId!: number;
  payPeriodEndDate!: string;
  taxationYear!: string;
  amount!: number;
  salary!: number;

  constructor(record?: RemittanceFileRecord) {
    if (record) {
      this.codeId = record['ENTRY TYPE'];
      this.payPeriodEndDate = DateUtils.simpleDateStringtoUTCDateString(record['PAY PERIOD END DATE']);
      this.taxationYear = DateUtils.yearToUTCDateString(record['APPLICABLE YEAR']);
      this.amount = record.AMOUNT;
      this.salary = record.SALARY;
    }
  }

  onDeserialize() {}
}
