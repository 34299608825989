import * as moment from 'moment';
import { WeekDay } from '@angular/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as timezone from 'moment-timezone';

export class DateUtils {
  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return DateUtils.currentTimestamp() - days * DateUtils.unixOneDay() < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return DateUtils.currentTimestamp() - hours * DateUtils.unixOneHour() < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return DateUtils.currentTimestamp() - minutes * 60 < ts;
  }

  private static isBefore(a: WeekDay, aTime: string, b: WeekDay, bTime: string) {
    return a < b || (a === b && aTime < bTime);
  }

  private static addOneWeek(day: WeekDay) {
    return day + 7;
  }

  static stringDateAndTimeBetween(
    startDay: WeekDay,
    endDay: WeekDay,
    nowDay: WeekDay,
    startTime: string,
    endTime: string,
    nowTime: string
  ) {
    // Is end day/time before start time (End = Sunday, Now = Tuesday) then overflow
    if (this.isBefore(endDay, endTime, startDay, startTime)) endDay = this.addOneWeek(endDay);
    // Is now day/time before start time (Now = Sunday, Start = Tuesday) then overflow
    if (this.isBefore(nowDay, nowTime, startDay, startTime)) nowDay = this.addOneWeek(nowDay);
    return !this.isBefore(endDay, endTime, nowDay, nowTime);
  }

  static stringDateBeforeNow(date: string): boolean {
    const now = new Date();
    const dateObj = new Date(date);
    return dateObj < now;
  }

  // Formatters

  static formatUnixToCurrentDateTime(d: number): string {
    if (d === 0) {
      return '--';
    } else {
      const utcMoment = moment.unix(d);
      return moment(utcMoment).local().format('MMM D[,] YYYY [at] LT');
    }
  }

  static formatUnixToCurrentDate(d: number): string {
    if (d === 0) {
      return '--';
    } else {
      const utcMoment = moment.unix(d);
      return moment(utcMoment).local().format('MMMM D[,] YYYY');
    }
  }

  static formatDateStringToCurrentDate(d: string): string {
    if (!d) {
      return '--';
    } else {
      const momentDate = moment(d);
      return moment(momentDate).local().format('MMM D[,] YYYY');
    }
  }

  static formatDateStringToCSTDate(d: string): string {
    const releaseDateInUTC = d?.substring(0, d.length - 1);
    const convertedDateTimeCST = timezone
      .tz(releaseDateInUTC, 'UTC')
      .tz('America/Regina')
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
    const formattedDate = moment(convertedDateTimeCST)?.format('MMM D[,] YYYY');
    const time = convertedDateTimeCST?.split('T')[1];
    const formattedTime = moment(time?.substring(0, time.length - 1), ['H:mm'])?.format('h:mm A');
    return formattedDate + ' at ' + formattedTime + ' CST';
  }

  static formatDateStringToCSTTime(d: string): string {
    if (!d) {
      return '-';
    }
    const releaseDateInUTC = d?.substring(0, d.length - 1);
    const convertedDateTimeCST = timezone
      .tz(releaseDateInUTC, 'UTC')
      .tz('America/Regina')
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
    const time = convertedDateTimeCST?.split('T')[1];
    const formattedTime = moment(time?.substring(0, time?.length - 1), ['H:mm'])?.format('h:mm A');
    return formattedTime + ' CST';
  }

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    // Jan 7, 2022 10:30 AM
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    // 8:30 PM
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToTimeAndSeconds(d: number): string {
    const utcMoment = moment.unix(d);
    // 8:30:00 PM
    return moment(utcMoment).local().format('LTS');
  }

  static formatUnixToExplicitTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('HH:mm:ss');
  }

  static formatUnixToDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixToShorthandDateWithYear(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D, YYYY');
  }

  static formatUnixToShorthandDateYearTime(d: number): string {
    const utcMoment = moment.unix(d);
    // June 21, 2021 at 9:41 PM
    return moment(utcMoment).local().format('MMM D[,] YYYY [at] LT');
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  static formatUnixRangeToShorthandDateWithYear(sd: number, ed: number): string {
    const startMoment = moment.unix(sd).local();
    const endMoment = moment.unix(ed).local();
    const start = startMoment.format('MMM D, YYYY');
    const end = endMoment.format('MMM D, YYYY');
    if (startMoment.isSame(endMoment, 'day')) {
      return start;
    } else {
      return `${start} - ${end}`;
    }
  }

  static formatUnixTimeRange(sd: number, ed: number): string {
    const startMoment = moment.unix(sd).local();
    const endMoment = moment.unix(ed).local();
    const start = startMoment.format('hh:mm A');
    const end = endMoment.format('hh:mm A');
    return `${start} - ${end}`;
  }

  static convertNgbDateObjectToString(dateObject: NgbDate): string {
    const { year, month, day } = dateObject;
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  /**
   * Converts a date string, a time string, to a formatted date string.
   * @param dateString - The date string in the format 'YYYY-MM-DD', representing the day, month, and year.
   * @param timeString - The time string in the format 'HH:mm', representing the hour and minute.
   * @returns - A formatted date string in the format 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'.
   */
  static formatAlertTimePickerDateToDateString(dateString: string, timeString: string): string {
    const combinedString = `${dateString} ${timeString}`;
    const parsedDate = moment(combinedString, 'YYYY-MM-DD HH:mm');
    const offsetHours = -6;
    const offsetString = (offsetHours < 0 ? '-' : '+') + Math.abs(offsetHours).toString().padStart(2, '0') + ':00';
    return parsedDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSS') + offsetString;
  }

  // Same as above, but the date string is in the format 'DD-MM-YYYY'.
  // This is because we are getting the raw date format from the input fields, instead of
  // the formatted for backend date string.
  static formatRawAlertTimePickerDateToDateString(dateString: string, timeString: string): string {
    const combinedString = `${dateString} ${timeString}`;
    const parsedDate = moment(combinedString, 'DD-MM-YYYY HH:mm');
    const offsetHours = -6;
    const offsetString = (offsetHours < 0 ? '-' : '+') + Math.abs(offsetHours).toString().padStart(2, '0') + ':00';
    return parsedDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSS') + offsetString;
  }

  static formatDateStringToAlertTimePickerStrings(dateString: string): {
    dateString: string;
    timeString: string;
  } {
    const parsedDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
    parsedDate.utcOffset(-6 * 60); // Central Time for Alert Start/End Dates
    const formattedDate = parsedDate.format('DD-MM-YYYY');
    const formattedTime = parsedDate.format('HH:mm');
    return {
      dateString: formattedDate,
      timeString: formattedTime
    };
  }

  // Reference

  static hoursMinutesSince(d: number): string {
    const differenceSeconds = this.currentTimestamp() - d;
    const hoursAndMinutes = moment.utc(differenceSeconds * 1000).format('H m');
    const hours = hoursAndMinutes.split(' ')[0];
    const mins = hoursAndMinutes.split(' ')[1];
    return `${hours} ${hours === '1' ? 'hr' : 'hrs'} ${mins} ${mins === '1' ? 'min' : 'mins'}`;
  }

  static weekDayToShorthandString(wd: WeekDay): string {
    switch (wd) {
      case WeekDay.Sunday:
        return 'Sun';
      case WeekDay.Monday:
        return 'Mon';
      case WeekDay.Tuesday:
        return 'Tues';
      case WeekDay.Wednesday:
        return 'Wed';
      case WeekDay.Thursday:
        return 'Thurs';
      case WeekDay.Friday:
        return 'Fri';
      case WeekDay.Saturday:
        return 'Sat';
    }
  }

  // Unix Helpers

  static currentTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static currentWeekday(): number {
    return new Date().getDay();
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }

  static unixOneMinute(): number {
    return 60;
  }

  static getSecondsSinceStartOfDay() {
    const timeNow = new Date();
    const hrsInSecs = timeNow.getHours() * 3600;
    const minsInSecs = timeNow.getMinutes() * 60;
    const secs = timeNow.getSeconds();
    return hrsInSecs + minsInSecs + secs;
  }

  static convert12HourTo24HourTime(time12Hour: string) {
    if (!time12Hour) {
      return time12Hour;
    }
    const [time, modifier] = time12Hour.toUpperCase().split(' ');
    const timeSplit = time.split(':');
    // using firstOrNull here breaks our testing framework
    let hoursString = timeSplit[0] ?? null;
    const [, minutes] = timeSplit;
    if (hoursString === '12') {
      hoursString = '00';
    }
    if (modifier === 'PM') {
      hoursString = `${parseInt(hoursString, 10) + 12}`;
    }
    return `${hoursString}:${minutes}`;
  }

  static convert24HourTo12HourTime(time24Hour: string) {
    if (!time24Hour) {
      return time24Hour;
    }
    let ts = time24Hour.toUpperCase();
    const H = parseInt(ts.substr(0, 2), 10);
    const h = H % 12 || 12;
    const hourString = h < 10 ? '0' + h : h; // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? ' AM' : ' PM';
    ts = hourString + ts.substr(2, 3) + ampm;
    return ts;
  }

  // Conversions
  static daysToSeconds(days: number): number {
    return days * 24 * 60 * 60;
  }

  static hoursToSeconds(hours: number): number {
    return hours * 60 * 60;
  }

  static yearToUTCDateString(year: number): string {
    const date = new Date(year, 0, 1, 0, 0, 0, 0);
    date.setUTCHours(0);
    return date.toISOString();
  }

  static simpleDateStringtoUTCDateString(date: string): string {
    const parsedDate = moment.utc(date, 'YYYYMMDD');
    return parsedDate.toISOString();
  }

  static swapDayAndMonth(date: string): string {
    // Convert DD/MM/YYYY to MM/DD/YYYY
    const dateParts = date.split('/');
    return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
  }
}
