import { RemittanceFileRecord } from './remittance-file-record';
import { ContributionEntry } from './contribution-entry';
import { Deserializable } from '../protocols/deserializable';

export class Contribution implements Deserializable {
  id!: string;
  remittanceId!: number;
  socialInsuranceNumber!: string;
  memberId: number | null = null;
  memberFirstName!: string;
  memberLastName!: string;
  entries: ContributionEntry[] = [];

  constructor(record: RemittanceFileRecord) {
    this.socialInsuranceNumber = record.SIN.toString(10);
    this.memberFirstName = record['FIRST NAME'];
    this.memberLastName = record['LAST NAME'];
    this.entries = [new ContributionEntry(record)];
  }

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.entries = Deserialize?.arrayOf(ContributionEntry, this.entries);
  }
}
