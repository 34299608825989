<div class="header-container row">
  <div class="header-row">
    <div class="col-md-9">
      <div *ngIf="title !== ''" class="header-text">
        {{ title }}
      </div>
    </div>
    <div class="col-md-3">
      <div class="button-container">
        <lib-reactive-form-group>
          <lib-reactive-form-text [inputName]="'search'" [placeholder]="'Search' | localization">
          </lib-reactive-form-text>
        </lib-reactive-form-group>
        <lib-button-neutral [iconSrc]="'assets/icons/dark/List.svg'" (buttonClicked)="rearrangeButtonClicked.emit()">{{
          'Rearrange' | localization
        }}</lib-button-neutral>
      </div>
    </div>
  </div>
</div>
