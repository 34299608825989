import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { RemittancesAPI } from '../api/remittances-api';
import { Observable, throwError } from 'rxjs';
import { Remittance } from '../models/remittances/remittance';
import { map } from 'rxjs/operators';
import { ContributionEntry } from '../models/remittances/contribution-entry';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Contribution } from '../models/remittances/contribution';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { RemittanceMember } from '../models/remittances/remittance-member';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { ODataResponse } from '../models/protocols/odata-response';
import { RemittanceAddDatesRequest } from '../models/remittances/remittance-add-dates-request';
import {
  RemittancePotentialMatchedMember,
  RemittanceVerificationMember
} from '../models/remittances/remittance-verification-member';
import { VerifyContributionRequest } from '../models/remittances/verify-contribution-request';
import { ContributionSummary } from '../models/remittances/contribution-summary';
import { RemittanceNewMember } from '../models/remittances/remittance-new-member';
import { RemittanceCreateNewMemberRequest } from '../models/remittances/remittance-create-new-member-request';
import { RemittanceUpdateNewMemberRequest } from '../models/remittances/remittance-update-new-member-request';
import { UpdateContributionRequest } from '../models/remittances/update-contribution-request';
import { RemittanceOwner } from '../models/remittances/remittance-owner';
import { RemittanceComment } from '../models/remittances/remittance-comment';
import { RemittanceCommentRequest } from '../models/remittances/remittance-comment-request';
import { CreateRemittancePaymentRequest } from '../models/payments/create-remittance-payment-request';
import { RemittancePayment } from '../models/payments/remittance-payment';
import { RemittanceOwnerUpdateRequest } from '../models/account/requests/remittance-owner-update-request';
import { RemittanceStatusIdEnum } from '../models/enum/remittance-status-id.enum';
import { CustomError } from '../models/shared/custom-error';
import { FailRemittanceRequest } from '../models/account/requests/fail-remittance-request';

@Injectable()
export class RemittanceDomainModel extends BaseDomainModel {
  private remittancesAPI = inject(RemittancesAPI);

  public getAllRemittances(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Remittance>> {
    oDataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner');
    return this.remittancesAPI.getAllRemittances(oDataQueryOptions);
  }

  public getOpenRemittancesForEmployer(employerId: number): Observable<Remittance[]> {
    return this.remittancesAPI.getOpenRemittancesForEmployer(employerId).pipe(map(response => response.value));
  }

  public getClosedRemittanceCountForEmployer(employerId: number): Observable<number> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setCount(true);
    queryOptions.setTop(0);
    return this.remittancesAPI
      .getClosedRemittancesForEmployer(employerId, queryOptions)
      .pipe(map(response => response['@odata.count'] ?? 0));
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    return this.remittancesAPI.getClosedRemittancesForEmployer(employerId, odataQueryOptions);
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    return this.remittancesAPI.getRemittanceById(remittanceId, queryOptions);
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    return this.remittancesAPI
      .getRemittanceHistory(remittanceId)
      .pipe(map(history => history.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())));
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    return this.remittancesAPI.createRemittance(req);
  }

  public updateRemittanceStatus(
    remittanceId: number,
    remittanceStatusId: RemittanceStatusIdEnum
  ): Observable<Remittance> {
    switch (remittanceStatusId) {
      case RemittanceStatusIdEnum.InReview:
        return this.remittancesAPI.updateRemittanceStatusInReview(remittanceId);
      case RemittanceStatusIdEnum.Failed:
        const req = new FailRemittanceRequest(remittanceId, 'Marked as Failed');
        return this.remittancesAPI.updateRemittanceStatusFailed(remittanceId, req);
      case RemittanceStatusIdEnum.Approved:
        return this.remittancesAPI.updateRemittanceStatusApproved(remittanceId);
      default:
        const err = new CustomError();
        err.title = $localize`Invalid Status`;
        return throwError(() => err);
    }
  }

  public updateRemittanceOwner(req: RemittanceOwnerUpdateRequest): Observable<Remittance> {
    return this.remittancesAPI.updateRemittanceOwner(req);
  }

  public addDatesToRemittance(remittanceId: number, req: RemittanceAddDatesRequest): Observable<Remittance> {
    return this.remittancesAPI.addDatesToRemittance(remittanceId, req);
  }

  public submitRemittance(remittanceId: number): Observable<Remittance> {
    return this.remittancesAPI.submitRemittance(remittanceId);
  }

  public deleteRemittance(remittanceId: number): Observable<any> {
    return this.remittancesAPI.deleteRemittance(remittanceId);
  }

  public getMembersOnRemittance(
    employerId: number,
    remittanceId: number,
    searchString?: string
  ): Observable<RemittanceMember[]> {
    return this.remittancesAPI
      .getMembersOnRemittance(employerId, remittanceId, searchString)
      .pipe(map(response => response.value));
  }

  public getRemittanceOwners(
    oDataQueryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<RemittanceOwner[]> {
    return this.remittancesAPI.getRemittanceOwners(oDataQueryOptions).pipe(map(response => response.value));
  }

  public getRemittanceComments(remittanceId: number): Observable<RemittanceComment[]> {
    return this.remittancesAPI.getRemittanceComments(remittanceId);
  }

  public addRemittanceComment(req: RemittanceCommentRequest): Observable<RemittanceComment[]> {
    return this.remittancesAPI.addRemittanceComment(req);
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    return this.remittancesAPI.addContribution(remittanceId, req);
  }

  public updateContribution(
    remittanceId: number,
    contributionId: string,
    req: UpdateContributionRequest
  ): Observable<Contribution> {
    return this.remittancesAPI.updateContribution(remittanceId, contributionId, req);
  }

  public deleteContribution(remittanceId: number, contributionId: string): Observable<any> {
    return this.remittancesAPI.deleteContribution(remittanceId, contributionId);
  }

  public getNewMembersOnRemittance(remittanceId: number): Observable<RemittanceNewMember[]> {
    return this.remittancesAPI.getNewMembersOnRemittance(remittanceId).pipe(map(response => response.value));
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<ODataResponse<Contribution>> {
    queryOptions.setOrderBy('memberLastName asc');
    return this.remittancesAPI.getContributionsForRemittance(remittanceId, queryOptions);
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    return this.remittancesAPI.createContributionEntry(remittanceId, contributionId, entries);
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    return this.remittancesAPI.updateContributionEntry(remittanceId, contributionId, entry);
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    return this.remittancesAPI.deleteContributionEntry(remittanceId, contributionId, entryId);
  }

  public createRemittanceNewMember(
    remittanceId: number,
    req: RemittanceCreateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    return this.remittancesAPI.createRemittanceNewMember(remittanceId, req);
  }

  public updateRemittanceNewMember(
    remittanceId: number,
    req: RemittanceUpdateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    return this.remittancesAPI.updateRemittanceNewMember(remittanceId, req);
  }

  public getContributionHistory(remittanceId: string, contributionId: string): Observable<RemittanceHistory[]> {
    return this.remittancesAPI.getContributionHistory(remittanceId, contributionId);
  }

  public getRemittanceMembersToVerify(remittanceId: string): Observable<ODataResponse<RemittanceVerificationMember>> {
    return this.remittancesAPI.getRemittanceMembersToVerify(remittanceId);
  }

  public verifyContribution(
    remittanceId: string,
    contributionId: string,
    req: VerifyContributionRequest
  ): Observable<Contribution> {
    return this.remittancesAPI.verifyContribution(remittanceId, contributionId, req);
  }

  public getContributionSummaryForRemittance(remittanceId: number): Observable<ContributionSummary> {
    return this.remittancesAPI.getContributionSummaryForRemittance(remittanceId.toString(10));
  }

  public createRemittancePayment(req: CreateRemittancePaymentRequest): Observable<RemittancePayment> {
    return this.remittancesAPI.createRemittancePayment(req);
  }

  public getPotentialMatchesForContribution(
    remittanceId: string,
    contributionId: string
  ): Observable<ODataResponse<RemittancePotentialMatchedMember>> {
    return this.remittancesAPI.getPotentialMatchesForContribution(remittanceId, contributionId);
  }
}
