import { Component, inject, Input, OnChanges } from '@angular/core';
import { AbstractSubmissionFormComponent } from '../submission-forms/abstract-submission-form/abstract-submission-form.component';
import { ChangePersonalInfoRequest } from '../../../../models/account/requests/submissionRequests/change-personal-info-request';
import { PersonalInfoSubmissionFormViewModel } from './personal-info-submission-form-view-model';
import { MediaUtils } from '../../../../utils/media-utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { StringExtensions } from '../../../../utils/string.extensions';

@Component({
  selector: 'app-personal-info-submission-form',
  templateUrl: './personal-info-submission-form.component.html',
  styleUrls: ['./personal-info-submission-form.component.scss'],
  providers: [PersonalInfoSubmissionFormViewModel]
})
export class PersonalInfoSubmissionFormComponent
  extends AbstractSubmissionFormComponent<ChangePersonalInfoRequest>
  implements OnChanges
{
  protected viewModel = inject(PersonalInfoSubmissionFormViewModel);

  @Input() decryptedMemberSIN: string = '';
  @Input() canViewFullSIN: boolean = true;

  public req!: ChangePersonalInfoRequest;

  public readonly loadingOpts$ = this.viewModel.loadingOpts$;
  public readonly genderDropdowns$ = this.viewModel.genderDropdowns$;
  public readonly pronounDropdowns$ = this.viewModel.pronounDropdowns$;
  public readonly isMobile$ = this.screenService.isMobile$;

  private _infoChanged = new BehaviorSubject<boolean>(false);
  public readonly infoChanged$ = this._infoChanged as Observable<boolean>;

  ngOnChanges(): void {
    this.req = new ChangePersonalInfoRequest(this.user, this.decryptedMemberSIN);
  }

  public formSubmitted(req: ChangePersonalInfoRequest): void {
    const formattedDate = StringExtensions.convertDateFormatForBackend(req.birthdate);
    req.birthdate = StringExtensions.convertDateToIsoString(formattedDate);
    if (!!this.fileUploader) {
      combineLatest([this.infoChanged$, this.fileUploader.selectedFiles$]).once(([changed, files]) => {
        if (changed) {
          const promises = files.map(file => MediaUtils.fileToUserFile(file));
          Promise.all(promises).then(userFiles => {
            this.viewModel.submitRequest(req, userFiles);
          });
        } else {
          this.viewModel.submitRequest(req);
        }
      });
    }
  }

  formChanged(current: ChangePersonalInfoRequest): void {
    if (this.req.genderId !== current.genderId || this.req.birthdate !== current.birthdate) {
      this._infoChanged.next(true);
    } else {
      this._infoChanged.next(false);
    }

    if (this.canViewFullSIN) {
      if (this.req.socialInsuranceNumber !== current.socialInsuranceNumber) {
        this._infoChanged.next(true);
      }
    }
  }

  protected readonly MediaUtils = MediaUtils;
  protected readonly StringExtensions = StringExtensions;
}
