import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Remittance } from '../models/remittances/remittance';
import { Endpoints } from './endpoints';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { ContributionEntry } from '../models/remittances/contribution-entry';
import { Contribution } from '../models/remittances/contribution';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { PortalService } from '../services/portal/portal.service';
import { RemittanceStatusUpdateRequest } from '../models/account/requests/remittance-status-update-request';
import { RemittanceMember } from '../models/remittances/remittance-member';
import { RemittanceHistory } from '../models/remittances/remittance-history';

@Injectable({
  providedIn: 'root'
})
export class RemittancesAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);
  private portalService = inject(PortalService);

  public serviceName = 'RemittancesAPI';

  public getClosedRemittancesForEmployer(
    employerId: number,
    odataParams: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getClosedRemittancesForEmployer(employerId);
    odataParams.setExpand('Type, Status, PaymentStatus, Source, Owner');
    const additionalParams = `employerId=${employerId}`;

    return this.apiClient.getOdata(url, Remittance, odataParams, null, additionalParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getOpenRemittancesForEmployer(employerId: number): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getOpenRemittancesForEmployer(employerId);
    const oDataQueryOptions = new ODataQueryOptions();
    oDataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner, Transactions($expand=Status)');
    const additionalParams = `employerId=${employerId}`;

    return this.apiClient.getOdata(url, Remittance, oDataQueryOptions, null, additionalParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceById(
    remittanceId: number,
    employerId: number,
    queryOptions: ODataQueryOptions
  ): Observable<Remittance> {
    const url = Endpoints.getRemittanceById(remittanceId, employerId);
    return this.apiClient.getOdataObj(url, Remittance, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    const url = Endpoints.createRemittance(req.employerId);
    return this.apiClient.postObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public submitRemittance(employerId: number, remittanceId: number): Observable<Remittance> {
    const url = Endpoints.submitRemittance(employerId, remittanceId);
    return this.apiClient.putObj(Remittance, url, {} as Remittance).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceStatus(
    employerId: number,
    remittanceId: number,
    req: RemittanceStatusUpdateRequest
  ): Observable<Remittance> {
    const url = Endpoints.updateRemittanceStatus(employerId, remittanceId);
    return this.apiClient.putObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteRemittance(employerId: number, remittanceId: number): Observable<any> {
    const url = Endpoints.deleteRemittance(employerId, remittanceId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMembersOnRemittance(
    employerId: number,
    remittanceId: number,
    searchString?: string
  ): Observable<ODataResponse<RemittanceMember>> {
    const url = Endpoints.getMembersOnRemittance(employerId, remittanceId);
    const queryOptions = new ODataQueryOptions();
    if (searchString) {
      queryOptions.setFilter(`contains(FullName, '${searchString}')`);
    }

    return this.apiClient.getOdata(url, RemittanceMember, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionsForRemittance(
    employerId: number,
    remittanceId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Contribution>> {
    const url = Endpoints.getContributionsForRemittance(employerId, remittanceId);
    queryOptions.setExpand('Entries($expand=Code;$count=true)');
    return this.apiClient.getOdata(url, Contribution, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addContribution(
    employerId: number,
    remittanceId: number,
    req: AddContributionRequest
  ): Observable<Contribution> {
    return this.portalService.isInternal$.pipe(
      take(1),
      switchMap(isInternal => {
        const url = isInternal
          ? Endpoints.addContributionAsInternalUser(employerId, remittanceId)
          : Endpoints.addContributionAsEmployerUser(employerId, remittanceId);
        return this.apiClient.postObj(Contribution, url, req);
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContribution(employerId: number, remittanceId: number, contributionId: string): Observable<any> {
    const url = Endpoints.deleteContribution(employerId, remittanceId, contributionId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createContributionEntry(
    employerId: number,
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    const url = Endpoints.createContributionEntry(employerId, remittanceId, contributionId);
    return this.apiClient.postObj(ContributionEntry, url, entry).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateContributionEntry(
    employerId: number,
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    const url = Endpoints.updateContributionEntry(employerId, remittanceId, contributionId, entry.id);
    return this.apiClient.putObj(ContributionEntry, url, entry).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContributionEntry(
    employerId: number,
    remittanceId: number,
    contributionId: string,
    entryId: string
  ): Observable<any> {
    const url = Endpoints.deleteContributionEntry(employerId, remittanceId, contributionId, entryId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceHistory(employerId: number, remittanceId: number): Observable<RemittanceHistory[]> {
    const url = Endpoints.getRemittanceHistory(employerId, remittanceId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
