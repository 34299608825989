import { Deserializable } from '../protocols/deserializable';
import { State } from '../types/dto/state';
import { EmploymentType } from '../employers/employer-history/types/employment-type';
import * as moment from 'moment';
import { DateUtils } from '../../utils/date-utils';

export class RemittanceNewMember implements Deserializable {
  public id!: string;
  public firstName!: string;
  public lastName!: string;
  public socialInsuranceNumber!: string;
  public birthDate!: Date;
  public newMemberDetails!: RemittanceNewMemberDetails;

  public onDeserialize(): void {
    if (!!this.newMemberDetails) {
      this.newMemberDetails = window?.injector?.Deserialize?.instanceOf(
        RemittanceNewMemberDetails,
        this.newMemberDetails
      );
    } else {
      this.newMemberDetails = new RemittanceNewMemberDetails();
    }
  }

  public prepareDatesForBackend(): void {
    if (!!this.newMemberDetails.formattedEmploymentStartDate) {
      this.newMemberDetails.employmentStartDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedEmploymentStartDate)
      );
    }
    if (!!this.newMemberDetails.formattedContributionStartDate) {
      this.newMemberDetails.contributionStartDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedContributionStartDate)
      );
    }
    if (!!this.newMemberDetails.formattedLeaveStartDate) {
      this.newMemberDetails.leaveStartDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedLeaveStartDate)
      );
    }
    if (!!this.newMemberDetails.formattedLeaveExpectedReturnDate) {
      this.newMemberDetails.leaveExpectedReturnDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedLeaveExpectedReturnDate)
      );
    }
  }
}

export class RemittanceNewMemberDetails implements Deserializable {
  public id!: string;
  public contributionId!: string;
  public email!: string;
  public employmentStartDate!: Date;
  public employmentTypeId!: number;
  public provinceOfEmploymentId!: number;
  public workFromHome!: boolean;
  public contributionStartDate!: Date;
  public employmentType!: EmploymentType;
  public provinceOfEmployment!: State;
  public onLeave!: boolean;
  public leaveTypeId: number | null = null;
  public leaveStartDate: Date | null = null;
  public leaveExpectedReturnDate: Date | null = null;
  public leaveContinuedContributions: boolean | null = null;

  // Not from API
  public formattedEmploymentStartDate!: string;
  public formattedContributionStartDate!: string;
  public formattedLeaveStartDate!: string;
  public formattedLeaveExpectedReturnDate!: string;

  onDeserialize(): void {
    this.formattedEmploymentStartDate = this.employmentStartDate
      ? moment(this.employmentStartDate).format('DD/MM/YYYY').toString()
      : '';
    this.formattedContributionStartDate = this.contributionStartDate
      ? moment(this.contributionStartDate).format('DD/MM/YYYY').toString()
      : '';
    this.formattedLeaveStartDate = this.leaveStartDate
      ? moment(this.leaveStartDate).format('DD/MM/YYYY').toString()
      : '';
    this.formattedLeaveExpectedReturnDate = this.leaveExpectedReturnDate
      ? moment(this.leaveExpectedReturnDate).format('DD/MM/YYYY').toString()
      : '';

    if (!!this.employmentType) {
      this.employmentType = window?.injector?.Deserialize?.instanceOf(EmploymentType, this.employmentType);
    }
    if (!!this.provinceOfEmployment) {
      this.provinceOfEmployment = window?.injector?.Deserialize?.instanceOf(State, this.provinceOfEmployment);
    }
  }
}
