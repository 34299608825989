<div class="card-container-title">
  <div class="breadcrumb-container">
    <div class="parent" (click)="goBack()" i18n>Alerts</div>
    <img class="arrow" [src]="'assets/icons/grey/Chevron Right.svg'" alt="" />
    <div class="child">{{ pageTitle$ | async }}</div>
  </div>
  <div class="d-flex justify-content-between">
    <span class="header">{{ pageTitle$ | async }}</span>
    <lib-button-neutral
      *ngIf="(canDeleteAlert$ | async) && !(isPastAlert$ | async)"
      class="delete"
      (buttonClicked)="openDeleteModal()"
      i18n>
      Delete Alert
    </lib-button-neutral>
  </div>
</div>
<div class="card-container" [style.margin-top.rem]="8" [style.padding-bottom.rem]="1">
  <div class="banner-preview-container" [style.margin-bottom.rem]="0" [style.margin-top.rem]="4">
    <app-banner-alert
      *ngIf="showBannerAlertPreview$ | async"
      [style.margin-bottom.rem]="2"
      [alert]="alertPreview$ | async"></app-banner-alert>
  </div>
  <div class="card-form-content-container" [style.margin-top.rem]="0">
    <div class="card-form-container">
      <app-alert-form
        *ngIf="!(isPastAlert$ | async)"
        [submitFormTrigger]="save.buttonClicked"
        (formSubmitted)="submitForm($event)"></app-alert-form>
      <app-view-past-alert *ngIf="isPastAlert$ | async"></app-view-past-alert>
    </div>
    <div class="card-form-action-container">
      <app-base-card [height]="'100%'">
        <div class="d-flex flex-column">
          <span class="preview" i18n>Preview</span>
          <div class="preview-subtext" i18n>View a live preview of what your alert will look like.</div>
        </div>
        <lib-button-primary (buttonClicked)="toggleShowAlertPreview()">
          {{ showAlertPreviewButtonText$ | async }}
        </lib-button-primary>
      </app-base-card>
      <app-card-alert *ngIf="showCardAlertPreview$ | async" [alert]="alertPreview$ | async"></app-card-alert>
    </div>
  </div>
</div>
<div class="action-buttons" [hidden]="!(canEditAlert$ | async) || (isPastAlert$ | async)">
  <lib-button-primary #save i18n>Save Alert</lib-button-primary>
  <lib-button-neutral (buttonClicked)="goBack()" i18n>Cancel</lib-button-neutral>
</div>
