import { Deserializable } from '../protocols/deserializable';
import { Selectable } from '../protocols/selectable';

export class TransactionEntryCodeType implements Deserializable, Selectable {
  public id!: number;
  public name!: string;

  getSelectionValue(): number {
    return this.id;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier() {
    return this.id;
  }
  getSelectionIsDisabled?(): boolean {
    return false;
  }
  onDeserialize() {}
}
