import { environment } from '../../environments/environment';
import { PortalType } from '../models/enum/shared/portal-type';
import { EmployerUser } from '../models/account/dto/employer-user';

export class Endpoints {
  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  static getBasePath(type: PortalType): string {
    switch (type) {
      case PortalType.Member:
        return 'members';
      case PortalType.Internal:
        return 'internalusers';
      case PortalType.Employer:
        return 'employers';
      default:
        throw new Error(`Invalid user type: ${type}`);
    }
  }

  static generateUrl(email: string, type: PortalType, pathSuffix: string): string {
    const basePath = this.getBasePath(type);
    return `${environment.mainServiceUrl}/${basePath}/${email}/${pathSuffix}`;
  }

  // Session

  static signIn(portalType: PortalType): string {
    return environment.mainServiceUrl + `/${this.getBasePath(portalType)}/login`;
  }

  static signOut(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'logout');
  }

  static refreshSession(email: string, portalType: PortalType): string {
    return this.generateUrl(email, portalType, 'refreshsession');
  }

  static changeEmail(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'changeemail');
  }

  static verifyEmail(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'verifyemail');
  }

  static changePassword(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'changepassword');
  }

  static changeMfaPhone(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'changemfaphonenumber');
  }

  static verifySmsCode(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'verifymfaphonenumber');
  }

  static verifyMfaToken(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'verifymfatokensetup');
  }

  static disableMfa(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'disablemfa');
  }

  static getVerifiedPhoneNumber(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'verifiedphonenumber');
  }

  static associateMfaToken(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'associatemfatoken');
  }

  // Member Auth

  static verifyMember(memberInviteId: string): string {
    return environment.mainServiceUrl + `/invites/${memberInviteId}/verify`;
  }

  static confirmMember(memberInviteId: string): string {
    return environment.mainServiceUrl + `/invites/${memberInviteId}/complete`;
  }

  static verifyMemberEmail(): string {
    return environment.mainServiceUrl + `/members/confirmmember`;
  }

  // Forgot Password

  static generateForgotPasswordUrl(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'forgotpassword');
  }

  static generateResetPasswordUrl(email: string, type: PortalType): string {
    return this.generateUrl(email, type, 'resetforgottenpassword');
  }

  // Members

  static addMember(employerId: string): string {
    return environment.mainServiceUrl + `/employers/${employerId}/members`;
  }

  static updateMember(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}`;
  }

  static updateMemberOnboardingInformation(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/onboardinginformation`;
  }

  static getEmployerDetails(memberInviteId: string): string {
    return environment.mainServiceUrl + `/invites/${memberInviteId}/employerdetails`;
  }

  static getMemberById(memberId: string): string {
    return environment.mainServiceUrl + `/odata/members/${memberId}`;
  }

  static getAllMembers(): string {
    return environment.mainServiceUrl + `/odata/members`;
  }

  static getMemberSIN(memberId: string): string {
    return environment.mainServiceUrl + `/odata/members/${memberId}/socialinsurancenumber`;
  }

  static memberForceChangeEmail(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/forcechangeemail`;
  }

  static memberComments(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/comments`;
  }

  static memberForceChangePassword(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/forceresetpassword`;
  }

  static searchMembersByIdOrName(): string {
    return environment.mainServiceUrl + `/odata/membersearchbyidorname`;
  }

  static getMembersForEmployer(employerId: string): string {
    return environment.mainServiceUrl + `/odata/employermembers/${employerId}`;
  }

  static resendMemberInvite(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/resendinvite`;
  }

  // Types

  static getStates(): string {
    return environment.mainServiceUrl + `/statetypes`;
  }

  static getGenders(): string {
    return environment.mainServiceUrl + `/gendertypes`;
  }

  static getPronouns(): string {
    return environment.mainServiceUrl + `/pronountypes`;
  }

  static getCountries(): string {
    return environment.mainServiceUrl + `/countrytypes`;
  }

  static getSubmissionStatusTypes(): string {
    return environment.mainServiceUrl + `/submissionstatustypes`;
  }

  static getAlertTypes(): string {
    return environment.mainServiceUrl + `/alertTypes`;
  }

  static getTransactionEntryCodeTypes(): string {
    return environment.mainServiceUrl + `/transactionentrycodetypes`;
  }

  static getTransactionStatusTypes(): string {
    return environment.mainServiceUrl + `/transactionstatustypes`;
  }

  static getMfaTypes(): string {
    return environment.mainServiceUrl + `/multifactorauthenticationtypes`;
  }

  static getEmployerPlanDesignatedUnitTypes(): string {
    return environment.mainServiceUrl + `/employerplandesignatedunittypes`;
  }

  static getEmployerPlanContributionRateBasisTypes(): string {
    return environment.mainServiceUrl + `/employerplancontributionratebasistypes`;
  }

  static getEmployerPlanContributionRateCalculationTypes(): string {
    return environment.mainServiceUrl + `/employerplanmatchedcontributionratecalculationtypes`;
  }

  static getEmployerPlanLessThanFullTimeParticipationTypes(): string {
    return environment.mainServiceUrl + `/employerplanlessthanfulltimeparticipationtypes`;
  }

  static getRemittanceFrequencyTypes(): string {
    return environment.mainServiceUrl + `/employerplanremittancefrequencytypes`;
  }

  static getEmployerPlanTypes(): string {
    return environment.mainServiceUrl + `/employerplantypes`;
  }

  static getMemberEmploymentTypes(): string {
    return environment.mainServiceUrl + `/memberemploymenttypes`;
  }

  static getMemberEmploymentLeaveTypes(): string {
    return environment.mainServiceUrl + `/memberemploymentleavetypes`;
  }

  static getMemberEmploymentStatusTypes(): string {
    return environment.mainServiceUrl + `/memberemploymentstatustypes`;
  }

  // Internal Users

  static getInternalUsers(): string {
    return environment.mainServiceUrl + `/odata/internalusers`;
  }

  static updateInternalUser(internalUserId: string): string {
    return environment.mainServiceUrl + `/internalusers/${internalUserId}`;
  }

  static forceResetInternalUserPassword(internalUserId: string): string {
    return environment.mainServiceUrl + `/internalusers/${internalUserId}/forceresetpassword`;
  }

  static forceResetInternalUserEmail(internalUserId: string): string {
    return environment.mainServiceUrl + `/internalusers/${internalUserId}/forcechangeemail`;
  }

  static createInternalUser(): string {
    return environment.mainServiceUrl + `/internalusers`;
  }

  // Roles and Permissions

  static getRolePath(type: PortalType): string {
    switch (type) {
      case PortalType.Member:
        return 'memberusers';
      case PortalType.Internal:
        return 'internalusers';
      case PortalType.Employer:
        return 'employerusers';
      default:
        throw new Error(`Invalid user type: ${type}`);
    }
  }

  static getRoleForUser(userId: string, portalType: PortalType): string {
    const rolePath = this.getRolePath(portalType);
    return environment.mainServiceUrl + `/odata/${rolePath}/${userId}/roles`;
  }

  static getInternalRoles(): string {
    return environment.mainServiceUrl + `/odata/internalroles`;
  }

  static getEmployerRoles(): string {
    return environment.mainServiceUrl + `/odata/employerroles`;
  }

  // Employers

  static getUsersForEmployer(employerId: string): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/users`;
  }

  static createEmployerUser(employerId: string): string {
    return environment.mainServiceUrl + `/employers/${employerId}/users`;
  }

  static getEmployerUserById(employerId: string, userId: string): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/users/${userId}`;
  }

  static updateEmployerUser(u: EmployerUser): string {
    return environment.mainServiceUrl + `/employers/${u?.employerId}/users/${u?.id}`;
  }

  static employerForceChangeEmail(u: EmployerUser): string {
    return environment.mainServiceUrl + `/employers/${u?.employerId}/users/${u?.id}/forcechangeemail`;
  }

  static employerForceChangePassword(u: EmployerUser): string {
    return environment.mainServiceUrl + `/employers/${u?.employerId}/users/${u?.id}/forceresetpassword`;
  }

  static searchEmployersByIdOrName(): string {
    return environment.mainServiceUrl + `/odata/employersearchbyidorname`;
  }

  static getEmploymentHistoryForMember(memberId: string): string {
    return (
      environment.mainServiceUrl +
      `/odata/members/${memberId}/employmenthistory?$expand=DesignatedUnit($expand=DesignatedUnitType),` +
      ` GoverningJurisdiction, ProvinceOfEmployment, EmploymentType,` +
      ` EmploymentStatus, LeaveDetails`
    );
  }

  static createEmploymentHistory(memberId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/employmenthistory`;
  }

  static updateEmploymentHistory(memberId: string, employerHistoryId: string): string {
    return environment.mainServiceUrl + `/members/${memberId}/employmenthistory/${employerHistoryId}`;
  }

  static createEmployee(employerId: string): string {
    return environment.mainServiceUrl + `/employers/${employerId}/members`;
  }

  // Employer Plans

  static getEmployerPlanById(employerPlanId: string): string {
    return environment.mainServiceUrl + `/odata/employerplans/${employerPlanId}`;
  }

  static updateEmployerPlan(employerPlanId: string): string {
    return environment.mainServiceUrl + `/employerplans/${employerPlanId}`;
  }

  static getEmployerPlanDesignatedUnitsByPlanId(employerPlanId: string): string {
    return environment.mainServiceUrl + `/odata/employerplans/${employerPlanId}/designatedunits`;
  }

  static getEmployerPlanDesignatedUnitById(employerPlanId: string, unitId: string): string {
    return (
      environment.mainServiceUrl +
      `/odata/employerplans/${employerPlanId}/designatedunits/${unitId}?
      expand=DesignatedUnitType,Versions($expand=ContributionRateBasisType,
      MatchedContributionRateType, UnmatchedContributionRateType,
      LessThanFullTimeParticipationType)`
    );
  }

  static createEmployerPlanDesignatedUnitVersion(employerPlanId: string, unitId: string): string {
    return environment.mainServiceUrl + `/employerplans/${employerPlanId}/designatedunits/${unitId}/versions`;
  }

  static updateEmployerPlanDesignatedUnitVersion(employerPlanId: string, unitId: string, versionId: string): string {
    return (
      environment.mainServiceUrl + `/employerplans/${employerPlanId}/designatedunits/${unitId}/versions/${versionId}`
    );
  }

  static getCommentsForEmployerPlan(planId: string): string {
    return environment.mainServiceUrl + `/odata/employerplans/${planId}/comments?expand=Mentions`;
  }

  static addEmployerPlanComment(planId: string): string {
    return environment.mainServiceUrl + `/employerplans/${planId}/comments`;
  }

  static createEmployerPlanDesignatedUnit(planId: string): string {
    return environment.mainServiceUrl + `/employerplans/${planId}/designatedunits`;
  }

  static updateEmployerPlanDesignatedUnit(planId: string, unitId: string): string {
    return environment.mainServiceUrl + `/employerplans/${planId}/designatedunits/${unitId}`;
  }

  static searchActiveDesignatedUnitsByIdOrName(employerId: string) {
    return environment.mainServiceUrl + `/odata/designatedunitsearchbyclientidorname?employerid=${employerId}&`;
  }

  // Submissions

  static getMemberSubmissions(): string {
    return environment.mainServiceUrl + `/odata/membersubmissions`;
  }

  static getMemberSubmissionById(id: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${id}`;
  }

  static getMemberSubmissionViewersForSubmissionType(subTypeId: string): string {
    return environment.mainServiceUrl + `/odata/membersubmissionviewers/${subTypeId}`;
  }

  static getMemberCommentMentionables(): string {
    return environment.mainServiceUrl + `/odata/membercommentmentionables`;
  }

  static createNameChangeSubmission(): string {
    return environment.mainServiceUrl + `/membersubmissions/namechange`;
  }

  static createAddressChangeSubmission(): string {
    return environment.mainServiceUrl + `/membersubmissions/addresschange`;
  }

  static createPersonalInfoSubmission(): string {
    return environment.mainServiceUrl + `/membersubmissions/personalinformation`;
  }

  static createUpdateContactInformationSubmission(): string {
    return environment.mainServiceUrl + `/membersubmissions/contactinformation`;
  }

  static createSubmissionAttachment(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/attachments`;
  }

  static addSubmissionComment(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/comments`;
  }

  static deleteSubmissionAttachment(attachmentId: string, submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/attachments/${attachmentId}`;
  }

  static getSubmissionAttachments(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/attachments/`;
  }

  static internalUserCancelSubmission(submissionId: string) {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/internalusercancel`;
  }

  static memberActionRequired(submissionId: string) {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/memberactionrequired`;
  }

  static submissionRejected(submissionId: string) {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/rejected`;
  }

  static submissionStatusToProcessing(submissionId: string) {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/processing`;
  }

  static submissionStatusToApproved(submissionId: string) {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/approved`;
  }

  static submissionStatusToReadyForApproval(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/readyforapproval`;
  }

  static getMemberSubmissionApprovers(): string {
    return environment.mainServiceUrl + `/odata/membersubmissionapprovers`;
  }

  static getMemberSubmissionOwners(): string {
    return environment.mainServiceUrl + `/odata/membersubmissionowners`;
  }

  static getMemberSubmissionOwnersForSubmissionType(subTypeId: string): string {
    return environment.mainServiceUrl + `/odata/membersubmissionowners/${subTypeId}`;
  }

  static getMemberSubmissionApproversForSubmissionType(subTypeId: string): string {
    return environment.mainServiceUrl + `/odata/membersubmissionapprovers/${subTypeId}`;
  }

  static getSubmissionRoleTemplates(): string {
    return environment.mainServiceUrl + `/odata/submissionroletemplates`;
  }

  static getAllSubmissionPermissions(): string {
    return environment.mainServiceUrl + `/odata/submissionpermissions`;
  }

  static getSubmissionPermissionTypes(): string {
    return environment.mainServiceUrl + `/submissionpermissiontypes`;
  }

  static getMemberSubmissionTypes(): string {
    return environment.mainServiceUrl + `/odata/membersubmissiontypes`;
  }

  static updateMemberSubmissionOwner(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/owner`;
  }

  static updateMemberSubmissionApprover(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/approver`;
  }

  static createMemberSubmissionReminder(submissionId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/reminders`;
  }

  static deleteMemberSubmissionReminder(submissionId: string, reminderId: string): string {
    return environment.mainServiceUrl + `/membersubmissions/${submissionId}/reminders/${reminderId}`;
  }

  // Alerts

  static createAlert(): string {
    return environment.mainServiceUrl + `/alerts`;
  }

  static updateAlert(alertId: string): string {
    return environment.mainServiceUrl + `/alerts/${alertId}`;
  }

  static getAlertById(alertId: string): string {
    return environment.mainServiceUrl + `/odata/alerts/${alertId}`;
  }

  static getAlerts(): string {
    return environment.mainServiceUrl + `/odata/alerts`;
  }

  // Transactions

  static getTransactionById(id: number): string {
    return environment.mainServiceUrl + `/odata/transactions/${id}`;
  }

  // Remittances

  static getRemittanceStatusTypes(): string {
    return environment.mainServiceUrl + `/remittancestatustypes`;
  }

  static getOpenRemittancesForEmployer(employerId: number): string {
    return environment.mainServiceUrl + `/odata/openremittances`;
  }

  static getClosedRemittancesForEmployer(employerId: number): string {
    return environment.mainServiceUrl + `/odata/closedremittances`;
  }

  static getRemittancesForEmployer(employerId: number): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/remittances`;
  }

  static getRemittanceById(remittanceId: number, employerId: number): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/remittances/${remittanceId}`;
  }

  static createRemittance(employerId: number): string {
    return environment.mainServiceUrl + `/employers/${employerId}/remittances`;
  }

  static submitRemittance(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/employers/${employerId}/remittances/${remittanceId}/submit`;
  }

  static updateRemittanceStatus(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/employers/${employerId}/remittances/${remittanceId}/status`;
  }

  static deleteRemittance(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/employers/${employerId}/remittances/${remittanceId}`;
  }

  static getMembersOnRemittance(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/remittances/${remittanceId}/members`;
  }

  static getRemittanceHistory(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/remittances/${remittanceId}/history`;
  }

  // Contributions

  static getContributionsForRemittance(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/odata/employers/${employerId}/remittances/${remittanceId}/contributions`;
  }

  static addContributionAsEmployerUser(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/employers/${employerId}/remittances/${remittanceId}/contributions/employer`;
  }

  static addContributionAsInternalUser(employerId: number, remittanceId: number): string {
    return environment.mainServiceUrl + `/employers/${employerId}/remittances/${remittanceId}/contributions/internal`;
  }

  static deleteContribution(employerId: number, remittanceId: number, contributionId: string): string {
    return (
      environment.mainServiceUrl +
      `/employers/${employerId}/remittances/${remittanceId}/contributions/${contributionId}`
    );
  }

  // Contribution Entries

  static createContributionEntry(employerId: number, remittanceId: number, contributionId: string): string {
    return (
      environment.mainServiceUrl +
      `/employers/${employerId}/remittances/${remittanceId}/contributions/${contributionId}/entries`
    );
  }

  static updateContributionEntry(
    employerId: number,
    remittanceId: number,
    contributionId: string,
    entryId: string
  ): string {
    return (
      environment.mainServiceUrl +
      `/employers/${employerId}/remittances/${remittanceId}/contributions/${contributionId}/entries/${entryId}`
    );
  }

  static deleteContributionEntry(
    employerId: number,
    remittanceId: number,
    contributionId: string,
    entryId: string
  ): string {
    return (
      environment.mainServiceUrl +
      `/employers/${employerId}/remittances/${remittanceId}/contributions/${contributionId}/entries/${entryId}`
    );
  }

  // Accounts
  static getAccountById(accountId: number): string {
    return environment.mainServiceUrl + `/odata/accounts/${accountId}`;
  }

  static getAccountEntries(accountId: number): string {
    return environment.mainServiceUrl + `/odata/accounts/${accountId}/transactionentries`;
  }

  // Release Notes

  static getReleaseNoteDocuments(): string {
    return environment.mainServiceUrl + `/odata/releasenotedocuments`;
  }

  static getReleaseNoteDocumentVersions(): string {
    return environment.mainServiceUrl + `/odata/releasenotedocumentversions`;
  }

  static getReleaseNoteDocumentById(id: string): string {
    return environment.mainServiceUrl + `/odata/releasenotedocuments/${id}`;
  }

  static createReleaseNoteDocument(): string {
    return environment.mainServiceUrl + `/releasenotedocuments`;
  }

  static releaseNoteDocumentById(id: string): string {
    return environment.mainServiceUrl + `/releasenotedocuments/${id}`;
  }

  static getLatestReleaseNoteDocument(): string {
    return environment.mainServiceUrl + `/odata/latestreleasenotedocument`;
  }
}
