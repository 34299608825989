import { inject, Injectable } from '@angular/core';
import { BaseService } from '@csspension/base-angular';
import { TypeAPI } from '../../api/type-api';
import { map, shareReplay } from 'rxjs/operators';
import { PhoneDropdownItem } from '../../models/shared/phone-dropdown-item';

@Injectable({
  providedIn: 'root'
})
export class TypeService extends BaseService {
  private typeAPI = inject(TypeAPI);

  constructor() {
    super();
  }

  // States
  public states$ = this.typeAPI.getStates().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Genders
  public genders$ = this.typeAPI.getGenders().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Pronouns
  public pronouns$ = this.typeAPI.getPronouns().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Countries
  public countries$ = this.typeAPI.getCountries().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Country Codes
  public countryCodes$ = this.countries$.pipe(
    map(countries =>
      countries.map(country => {
        return new PhoneDropdownItem(country);
      })
    )
  );
  // Submission Statuses
  public submissionStatuses$ = this.typeAPI
    .getSubmissionStatusTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Alert Types
  public alertTypes$ = this.typeAPI.getAlertTypes().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Transaction Entry Code Types
  public transactionEntryCodeTypes$ = this.typeAPI
    .getTransactionEntryCodeTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Transaction Status Types
  public transactionStatusTypes$ = this.typeAPI
    .getTransactionStatusTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Remittance Status Types
  public remittanceStatusTypes$ = this.typeAPI
    .getRemittanceStatusTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Multi-Factor Authentication Types
  public mfaTypes$ = this.typeAPI.getMfaTypes().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Remittance Frequency Types
  public remittanceFrequencyTypes$ = this.typeAPI
    .getRemittanceFrequencyTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Designated Unit Types
  public designatedUnitTypes$ = this.typeAPI
    .getEmployerPlanDesignatedUnitTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Designated Unit Contribution Rate Basis Types
  public designatedUnitContributionRateBasisTypes$ = this.typeAPI
    .getEmployerPlanContributionRateBasisTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Designated Unit Contribution Rate Calculation Types
  public designatedUnitContributionRateCalculationTypes$ = this.typeAPI
    .getEmployerPlanContributionRateCalculationTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Designated Unit Less Than Full Time Participation Types
  public designatedUnitLessThanFullTimeParticipationTypes$ = this.typeAPI
    .getEmployerPlanLessThanFullTimeParticipationTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Member Employment Types
  public memberEmploymentTypes$ = this.typeAPI
    .getMemberEmploymentTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Member Employment Status Types
  public memberEmploymentStatusTypes$ = this.typeAPI
    .getMemberEmploymentStatusTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  // Member Employment Leave Types
  public memberEmploymentLeaveTypes$ = this.typeAPI
    .getMemberEmploymentLeaveTypes()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
}
