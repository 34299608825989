import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseInfoItem } from '../../../profile/models/base-info-item';

@Component({
  selector: 'app-static-info-container',
  templateUrl: './static-info-container.component.html',
  styleUrls: ['./static-info-container.component.scss', '../../../profile/components/profile.component.scss']
})
export class StaticInfoContainerComponent extends BaseComponent implements OnChanges {
  // All strings must be pre-localized.
  @Input() items!: BaseInfoItem[];
  @Input() title!: string;
  @Input() lastSection: boolean = false;
  @Input() hideBottomDivider: boolean = false;
  @Input() actionButtonText = $localize`Edit`;
  @Input() hideActionButton = false;
  @Input() separateFirstItem = true;
  @Input() twoColLayout = false;

  @Output() actionButtonClicked = new EventEmitter<void>();

  private _title = new BehaviorSubject<string>(this.title);
  public title$ = this._title as Observable<string>;

  private _items = new BehaviorSubject<BaseInfoItem[]>([]);
  public items$ = this._items as Observable<BaseInfoItem[]>;

  private _showFullSin = new BehaviorSubject<boolean>(false);
  public showFullSin$ = this._showFullSin as Observable<boolean>;

  setupBindings(): void {}

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) this._title.next(this.title);
    if (changes.items) {
      this._items.next(this.items);
      this.title$.once(title => this.trimItemsArray(title));
    }
  }

  private trimItemsArray(title: string): void {
    this.items$.once(items => {
      if (!title) {
        this._items.next(items.slice(1));
      }
    });
  }

  public toggleSin(ev: MouseEvent): void {
    ev.preventDefault();
    this.showFullSin$.once(showFullSin => this._showFullSin.next(!showFullSin));
  }

  public navigateToLink(url: string): void {
    // TODO: Implement when we have a clickable info item
  }
}
