import { LoggableAPI } from '../models/protocols/loggable-api';
import { inject, Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { Endpoints } from './endpoints';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { EmployerUser } from '../models/account/dto/employer-user';
import { CreateEmployerUserRequest } from '../models/account/requests/create-employer-user-request';
import { Deserializable } from '../models/protocols/deserializable';
import { Role } from '../models/roles/role';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { HttpResponse } from '@angular/common/http';
import { EmployerPlan } from '../models/employers/plans/employer-plan';
import { EmployerPlanDesignatedUnit } from '../models/employers/plans/employer-plan-designated-unit';
import { EmployerPlanDesignatedUnitVersion } from '../models/employers/plans/employer-plan-designated-unit-version';
import { EmployerPlanCommentRequest } from '../models/employers/plans/employer-plan-comment-request';
import { EmployerPlanComment } from '../models/employers/plans/employer-plan-comment';
import { CreateEmployerPlanDesignatedUnit } from '../models/employers/plans/create-employer-plan-designated-unit';
import { EmployerSearchResult } from '../models/employers/dto/employer-search-result';
import { DesignatedUnitSearchResult } from '../models/employers/plans/designated-unit-search-result';
import { CreateMemberRequest } from '../models/account/requests/create-member-request';
import { MemberUser } from '../models/account/dto/member-user';
import { Employer } from '../models/account/dto/employer';

@Injectable({
  providedIn: 'root'
})
export class EmployersAPI implements LoggableAPI {
  constructor() {}

  private apiClient = inject(ApiClient);

  public serviceName = 'Employers';

  public getEmployer(employerId: string): Observable<Employer> {
    const url = Endpoints.getEmployer(employerId);
    return this.apiClient.getOdataObj<Employer>(url, Employer).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getUsersForEmployers(
    oDataQueryOptions: ODataQueryOptions,
    employerId: string
  ): Observable<ODataResponse<EmployerUser>> {
    const url = Endpoints.getUsersForEmployer(employerId);
    oDataQueryOptions.setExpand('Roles');
    return this.apiClient.getOdata<EmployerUser>(url, EmployerUser, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createEmployerUser(req: CreateEmployerUserRequest, employerId: string): Observable<EmployerUser> {
    const url = Endpoints.createEmployerUser(employerId);
    return this.apiClient.postObj<EmployerUser, Deserializable>(EmployerUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerRoles(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Role>> {
    const url = Endpoints.getEmployerRoles();
    // todo - we don't have an employer specific permission for this.
    const permissionHeaders = { permissions: [0] };
    return this.apiClient.getOdata(url, Role, odataQueryOptions, permissionHeaders).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerUserById(employerId: string, userId: string): Observable<EmployerUser> {
    const url = Endpoints.getEmployerUserById(employerId, userId);
    const odataQueryOptions = new ODataQueryOptions();
    odataQueryOptions.setExpand('Roles');
    return this.apiClient.getOdataObj<EmployerUser>(url, EmployerUser, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateEmployerUser(u: EmployerUser): Observable<EmployerUser> {
    const url = Endpoints.updateEmployerUser(u);
    return this.apiClient.putObj(EmployerUser, url, u).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public forceChangeEmail(u: EmployerUser, req: ChangeEmailRequest): Observable<EmployerUser> {
    const url = Endpoints.employerForceChangeEmail(u);
    return this.apiClient.postObj<EmployerUser, Deserializable>(EmployerUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // This endpoint works to both force reset another Employer User's password, and resend the welcome email.
  public forceResetPassword(u: EmployerUser): Observable<HttpResponse<any>> {
    const url = Endpoints.employerForceChangePassword(u);
    return this.apiClient.simpleBodylessPost(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerPlanById(employerPlanId: string): Observable<EmployerPlan> {
    const url = Endpoints.getEmployerPlanById(employerPlanId);
    const odataQueryOptions = new ODataQueryOptions();
    odataQueryOptions.setExpand('DesignatedUnits', 'Employer', 'EmployerPlanType', 'RemittanceFrequencyType');
    return this.apiClient.getOdataObj<EmployerPlan>(url, EmployerPlan, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateEmployerPlan(ep: EmployerPlan): Observable<EmployerPlan> {
    const url = Endpoints.updateEmployerPlan(ep.id.toString(10));
    return this.apiClient.putObj(EmployerPlan, url, ep).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerPlanDesignatedUnitsByPlanId(
    employerPlanId: string,
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<EmployerPlanDesignatedUnit>> {
    const url = Endpoints.getEmployerPlanDesignatedUnitsByPlanId(employerPlanId);
    return this.apiClient.getOdata<EmployerPlanDesignatedUnit>(url, EmployerPlanDesignatedUnit, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerPlanDesignatedUnitById(
    employerPlanId: string,
    unitId: string
  ): Observable<EmployerPlanDesignatedUnit> {
    const url = Endpoints.getEmployerPlanDesignatedUnitById(employerPlanId, unitId);
    return this.apiClient.getOdataObj<EmployerPlanDesignatedUnit>(url, EmployerPlanDesignatedUnit).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createEmployerPlanDesignatedUnit(
    req: CreateEmployerPlanDesignatedUnit
  ): Observable<EmployerPlanDesignatedUnit> {
    const url = Endpoints.createEmployerPlanDesignatedUnit(req.employerPlanId.toString(10));
    return this.apiClient
      .postObj<EmployerPlanDesignatedUnit, Deserializable>(EmployerPlanDesignatedUnit, url, req)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public updateEmployerPlanDesignatedUnit(req: EmployerPlanDesignatedUnit): Observable<EmployerPlanDesignatedUnit> {
    const url = Endpoints.updateEmployerPlanDesignatedUnit(req.employerPlanId.toString(10), req?.id);
    return this.apiClient.putObj<EmployerPlanDesignatedUnit, Deserializable>(EmployerPlanDesignatedUnit, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createEmployerPlanDesignatedUnitVersion(
    req: EmployerPlanDesignatedUnitVersion,
    unitId: string
  ): Observable<EmployerPlanDesignatedUnitVersion> {
    const url = Endpoints.createEmployerPlanDesignatedUnitVersion(req.employerPlanId.toString(10), unitId);
    return this.apiClient
      .postObj<EmployerPlanDesignatedUnitVersion, Deserializable>(EmployerPlanDesignatedUnitVersion, url, req)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public updateEmployerPlanDesignatedUnitVersion(
    req: EmployerPlanDesignatedUnitVersion
  ): Observable<EmployerPlanDesignatedUnitVersion> {
    const url = Endpoints.updateEmployerPlanDesignatedUnitVersion(
      req.employerPlanId.toString(10),
      req.designatedUnitId.toString(10),
      req.id
    );
    return this.apiClient.putObj(EmployerPlanDesignatedUnitVersion, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerPlanComments(employerPlanId: string): Observable<EmployerPlanComment[]> {
    const url = Endpoints.getCommentsForEmployerPlan(employerPlanId);
    return this.apiClient.getOdata(url, EmployerPlanComment).pipe(
      map(res => {
        const comments = res.value;
        return comments.sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addEmployerPlanComment(req: EmployerPlanCommentRequest): Observable<EmployerPlanComment[]> {
    const url = Endpoints.addEmployerPlanComment(req.employerPlanId);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        const comments = window?.injector?.Deserialize?.arrayOf(EmployerPlanComment, res.body);
        return comments.sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public searchEmployersByIdOrName(
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<EmployerSearchResult>> {
    const url = Endpoints.searchEmployersByIdOrName();
    return this.apiClient.getOdata<EmployerSearchResult>(url, EmployerSearchResult, odataQueryOptions, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public searchActiveDesignatedUnitsByIdOrName(
    odataQueryOptions: ODataQueryOptions,
    employerId: string
  ): Observable<ODataResponse<DesignatedUnitSearchResult>> {
    const url = Endpoints.searchActiveDesignatedUnitsByIdOrName(employerId);
    return this.apiClient
      .getOdata<DesignatedUnitSearchResult>(url, DesignatedUnitSearchResult, odataQueryOptions, null, undefined, true)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public createMemberUser(req: CreateMemberRequest, employerId: string): Observable<MemberUser> {
    const url = Endpoints.createEmployee(employerId);
    return this.apiClient.postObj<MemberUser, Deserializable>(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
