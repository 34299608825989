import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Location } from '@angular/common';
import { ScreenService } from 'src/app/services/screen-service.service';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { InternalUser } from '../../../../models/account/dto/internal-user';
import { EmployerUser } from '../../../../models/account/dto/employer-user';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';
import { AddRoleMap } from '../../../../models/shared/add-role-map';
import { ViewUserFormSubmissions } from './view-user-view-model';
import { PortalService } from '../../../../services/portal/portal.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent extends BaseComponent {
  constructor(protected location: Location) {
    super();
  }

  private screenService = inject(ScreenService);
  private portalService = inject(PortalService);

  public isMobile$ = this.screenService.isMobile$;
  public isInternal$ = this.portalService.isInternal$;
  public isEmployer$ = this.portalService.isEmployer$;

  @Input() loadingOpts: LoadingOptions = new LoadingOptions();
  @Input() user: InternalUser | EmployerUser | null = null;
  @Input() canEditUser: boolean = false;
  @Input() statusDropdowns: DropDownItem[] = [];
  @Input() userRoleMap: AddRoleMap = new AddRoleMap();
  @Input() lastLoginTime: string = '';
  @Input() userHasNotLoggedIn: boolean = false;

  @Output() userUpdated = new EventEmitter<ViewUserFormSubmissions>();
  @Output() resendInvite = new EventEmitter<boolean>();
  @Output() openChangeEmailModal = new EventEmitter<void>();

  setupBindings(): void {}

  setupViews(): void {}

  public goBack(): void {
    this.location.back();
  }
}
