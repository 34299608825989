import { Component, inject, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { AccessedByPortalType } from '../../../../../interfaces/accessed-by-portal-type';
import { RequiredPermissions } from '../../../../../interfaces/required-permissions';
import { PortalType } from '../../../../../models/enum/shared/portal-type';
import { AlertDetailsViewModel } from './alert-details-view-model';
import { Router } from '@angular/router';
import { StringExtensions } from '../../../../../utils/string.extensions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';
import { combineLatest, of } from 'rxjs';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { map } from 'rxjs/operators';
import { ModalDeleteAlert } from '../../../../../modals/modal-delete-alert';
import { AlertStatusStringEnum } from '../../../../../models/alerts/enum/alert-status.enum';

export const MAX_ALERT_TITLE_LENGTH = 80;
export const MAX_ALERT_DESCRIPTION_LENGTH = 200;
export const MAX_ALERT_BUTTON_LENGTH = 40;

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss'],
  providers: [AlertDetailsViewModel]
})
export class AlertDetailsComponent extends BaseComponent implements AccessedByPortalType, RequiredPermissions {
  protected viewModel = inject(AlertDetailsViewModel);
  private router = inject(Router);
  private ngbModal = inject(NgbModal);
  private injector = inject(Injector);

  public alert$ = this.viewModel.alert$;

  public isCardTypeAlert$ = this.viewModel.isCardTypeAlert$;

  public pageTitle$ = of($localize`Alert Details`);
  public canEditAlert$ = this.viewModel.canEditAlert$;
  public isPastAlert$ = this.viewModel.isPastAlert$;
  public canDeleteAlert$ = combineLatest([this.alert$, this.canEditAlert$]).pipe(
    map(([alert, canEdit]) => {
      return canEdit && !!alert?.id && alert.status !== AlertStatusStringEnum.Past;
    })
  );

  public alertPreview$ = this.viewModel.alertPreview$;
  public showAlertPreview$ = this.viewModel.showAlertPreview$;

  public showAlertPreviewButtonText$ = this.showAlertPreview$.pipe(
    map(show => {
      return show ? $localize`Hide Preview` : $localize`Show Preview`;
    })
  );

  public showCardAlertPreview$ = combineLatest([this.showAlertPreview$, this.isCardTypeAlert$]).pipe(
    map(([show, isCard]) => show && isCard)
  );

  public showBannerAlertPreview$ = combineLatest([this.showAlertPreview$, this.isCardTypeAlert$]).pipe(
    map(([show, isCard]) => show && !isCard)
  );

  goBack(): void {
    this.router.navigate(['/settings/alerts']).then();
  }

  public toggleShowAlertPreview(): void {
    this.viewModel.toggleShowAlertPreview();
  }

  public submitForm(alert: Alert): void {
    this.viewModel.createAlert(alert);
  }

  public openDeleteModal(): void {
    this.alert$.once(alert => {
      ModalDeleteAlert.open(this.ngbModal, this.injector).then(result => {
        if (result) {
          this.viewModel.deleteAlert(alert.id.toString());
        }
      });
    });
  }

  public getAllowedPortalTypes(): PortalType[] {
    return [PortalType.Internal];
  }

  getRequiredPermissions(): number[] {
    return [46];
  }

  setupBindings(): void {}

  setupViews(): void {}

  protected readonly StringExtensions = StringExtensions;
  protected readonly AlertTypeEnum = AlertTypeEnum;
}
